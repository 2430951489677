export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'homes',
      displayName: 'menu.homes',
      meta: {
        icon: 'home',
      },
    },
    {
      name: 'services',
      displayName: 'menu.services',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'galleries',
      displayName: 'menu.galleries',
      meta: {
        icon: 'store',
      },
    },
    {
      name: 'libraries',
      displayName: 'menu.libraries',
      meta: {
        icon: 'post_add',
      },
    },
  ] as INavigationRoute[],
}
