import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDB_225qUTAyrvFQK2x8N9DFXedtdrqLDA',
  authDomain: 'gen-studio-9d6a8.firebaseapp.com',
  projectId: 'gen-studio-9d6a8',
  storageBucket: 'gen-studio-9d6a8.appspot.com',
  messagingSenderId: '571486578897',
  appId: '1:571486578897:web:aa6c61fb9a91eb223efdf0',
  measurementId: 'G-YXF3Q8VFJK',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export { db, app }
